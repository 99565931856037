import store from '@/store/index';
const { user } = store.getters;
const disabledDate = (date) => {
    const now = new Date();
    const nowMonth = now.getMonth();
    const nowYear = now.getFullYear();
    const monthStartData = new Date(nowYear, nowMonth, 1); // 当月第一天
    // const monthEndData = new Date(nowYear, nowMonth + 1, 0); // 当月最后一天
    // return Boolean(date.getTime() > monthEndData.getTime() || date.getTime() < monthStartData.getTime());
    return Boolean(date.getTime() >= monthStartData.getTime());
};
export const config = {
    name: 'accountManage',
    searchFields: [
        {
            name: '责任人类型',
            value: 'respType',
            type: 'select',
            optionNum: 'NEOCRM_RESP_TYPE', // TODO
            isFixed: true,
            span: 3
        },
        // {
        //     name: '客户名称',
        //     value: 'customerName',
        //     isFixed: true,
        //     type: 'input',
        //     span: 3
        // },
        {
            name: '客户名称',
            value: 'customerName',
            type: 'advanceMulti',
            advanceConfig: [
                { prop: 'customerName', value: '客户名称', 'displayName': 'customerName' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            tableConfig: [
                { prop: 'customerName', value: '客户名称', 'displayName': 'customerName' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            cbParams: [
                'customerCode#customerCode',
                'customerName#customerName'
            ],
            beforeRequest: (params) => {
                params.customerStatus = '1'; // 有效
                return params;
            },
            isFixed: true,
            span: 3
        },
        {
            name: '责任人划分方式',
            value: 'respDivideList',
            type: 'select',
            optionNum: 'NEOCRM_RESP_DIVIDE',
            isFixed: true,
            multiple: true,
            span: 3
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称', 'displayName': 'companyNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode', 'companyNameCn#companyName']
        },
        {
            name: '经营中心',
            value: 'businessCenterName',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 3,
            advanceConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            tableConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称', 'displayName': 'businessCenterName' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            prefix: '/api-lcs',
            advanceUrl: '/bid/queryCompanyByCodeAndName',
            cbParams: ['businessCenterCode', 'businessCenterName']
            // advanceCascade: 'companyCode#companyCode' // 关联入参只针对单选有效
        },
        {
            name: '服务平台',
            value: 'siteName',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'siteNameCn', 'value': '服务平台名称' },
                { 'prop': 'siteCode', 'value': '服务平台编码' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'prop': 'siteNameCn', 'value': '服务平台名称', 'displayName': 'esusUserNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popSite`,
            cbParams: ['siteCode', 'siteNameCn#siteName']
            // advanceCascade: 'companyCode#companyCode' // 关联入参只针对单选有效
        },
        {
            name: '责任人',
            value: 'ownerName',
            type: 'advanceMulti',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#ownerName', 'esusLoginName#ownerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            }
        },
        {
            name: '承接时间',
            value: 'effectTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '承接时间从',
            endPlaceholder: '承接时间到',
            span: 6
        }
    ],
    selection: true,
    index: false,
    columns: [
        {
            label: '责任人类型',
            prop: 'respType',
            type: 'select',
            optionsKey: 'NEOCRM_RESP_TYPE', // TODO
            mustFill: true,
            placeholder: '责任人类型',
            width: 120
        },
        {
            label: '客户名称',
            prop: 'customerName',
            type: 'advance',
            name: '客户名称',
            mustFill: true,
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'accountName', value: '客户名称' },
                { prop: 'customItem232__c', value: '客户编码' },
                { prop: 'customItem169__c', value: '客户简称' },
                { prop: 'accountId', value: '账号ID' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/contract/customer/account/relation',
            cbParams: [
                'customItem232__c#customerCode',
                'accountName#customerName'
            ],
            beforeRequest: (params) => {
                params.userCode = user.userCode;
                return params;
            },
            width: 160
        },
        // {
        //     label: '客户名称',
        //     prop: 'customerName',
        //     width: 140,
        //     sort: true,
        //     mustFill: true
        // },
        {
            label: '客户编码',
            prop: 'customerCode',
            width: 140,
            sort: true,
            mustFill: true
        },
        {
            label: '责任人划分方式',
            prop: 'respDivide',
            type: 'select',
            optionsKey: 'NEOCRM_RESP_DIVIDE', // TODO
            mustFill: true,
            placeholder: '责任人划分方式',
            width: 140
        },
        {
            label: '分公司',
            prop: 'companyName',
            type: 'advance',
            name: '添加分公司',
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode', 'companyNameCn#companyName'],
            mustFill: false,
            width: 180
        },
        {
            label: '经营中心',
            prop: 'businessCenterName',
            type: 'advance',
            name: '添加经营中心',
            advanceConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            tableConfig: [
                { prop: 'businessCenterName', value: '责任经营中心名称' },
                { prop: 'businessCenterCode', value: '责任经营中心编码' }
            ],
            prefix: '/api-lcs',
            advanceUrl: '/bid/queryCompanyByCodeAndName',
            cbParams: ['businessCenterCode', 'businessCenterName'],
            advanceCascade: 'companyCode#companyCode',
            mustFill: false,
            width: 180
        },
        {
            label: '服务平台',
            prop: 'siteName',
            type: 'advance',
            name: '添加服务平台',
            advanceConfig: [
                { 'prop': 'siteNameCn', 'value': '服务平台名称' },
                { 'prop': 'siteCode', 'value': '服务平台编码' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'prop': 'siteNameCn', 'value': '服务平台名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popSite`,
            cbParams: ['siteCode', 'siteNameCn#siteName'],
            advanceCascade: 'companyCode#companyCode',
            mustFill: false,
            width: 180
        },
        {
            label: '责任人',
            prop: 'ownerName',
            value: 'ownerName',
            name: '添加责任人',
            type: 'advance',
            mustFill: true,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#ownerName', 'esusLoginName#ownerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            },
            width: 150
        },
        {
            label: '承接开始时间',
            prop: 'effectStartTime',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            pickerOptions: { disabledDate },
            mustFill: true,
            width: 160
        },
        {
            label: '承接结束时间',
            prop: 'effectEndTime',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            pickerOptions: { disabledDate },
            mustFill: true,
            width: 160
        },
        {
            label: '最后修改人',
            prop: 'updateUserName',
            width: 154
        },
        {
            label: '数据同步时间',
            prop: 'createTime',
            width: 154
        }
    ],
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: []
    }
};

<template>
    <div class="customer-person">
        <Paper class="customer-person-paper">
            <list-layout-paper>
                <template slot="header-search" class="searchBox">
                    <search-box
                        ref="searchBox"
                        :search-key="config.name"
                        :fields="config.searchFields"
                        :showResetBtn="true"
                        @search-change="accountList.searchList"
                    />
                </template>
                <template slot="header-button">
                    <lotsButton
                        v-has="authorityCode.addNew"
                        type="primary"
                        size="mini"
                        @click="addNew"
                        title="新增">
                        <span class="icon iconfont">&#xe66a;</span> 新增
                    </lotsButton>
                    <lotsButton
                        v-has="authorityCode.save"
                        type="primary"
                        icon="el-icon-circle-check"
                        size="mini"
                        title="保存"
                        :loading="saveLoading"
                        @click="handleSave">保存
                    </lotsButton>
                    <lotsButton
                        v-has="authorityCode.delete"
                        icon="el-icon-delete"
                        type="primary"
                        size="mini"
                        title="删除"
                        :loading="deleteLoading"
                        @click="handleDelete">删除
                    </lotsButton>
                    <report-export
                        #default="{ submit }"
                        reportCode="REPORT_CRM_CUSTOMER_MANAGER">
                        <lotsButton
                            v-has="authorityCode.export"
                            icon="el-icon-download"
                            type="primary"
                            size="mini"
                            @click="handleExport(submit, 'split')">导出
                        </lotsButton>
                    </report-export>

                    <!-- <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                        <el-button
                            icon="iconfont icon-list_install"
                            type="primary"
                            size="mini"
                            :title="'列配置'"
                            @click="showColumnConfigDialog"
                            plain />
                    </el-tooltip> -->
                </template>
                <!-- 表格 -->
                <template v-slot:list="{ tableHeight }">
                    <edit-table-box
                        ref="editTableBox"
                        v-loading="accountList.tableLoading"
                        :control="true"
                        :selection="config.selection"
                        :columns="config.columns"
                        :height="tableHeight"
                        :tableData="accountList.totalData"
                        :actions="config.actions"
                        :allowRowClick="false"
                        :key="rendertable"
                        @selection-change="accountList.handleSelectedRow"
                        @element-change="handleChangeRow"
                        @btn-event="handleActive">
                        <template v-slot:col-append="{item, row, rIndex}">
                            <div class="col-append-box" v-show="item.prop === 'customerMobile' || item.prop==='customerEmail'">
                                <div class="col-append-content">
                                    {{ row[item.prop] ? ((item.prop === 'customerMobile' ? row.showMobile : row.showEmail) ? row[item.prop] : '****') : ''}}</div>
                                <div class="col-append-btn">
                                    <i
                                        title="查看完整信息"
                                        v-show="row[item.prop] && (item.prop === 'customerMobile' ? !row.showMobile : !row.showEmail)"
                                        class="el-icon-view eye-btn"
                                        :class="{'bigData-btn-viewPhone': item.prop === 'customerMobile', 'bigData-btn-viewEmail': item.prop==='customerEmail'}"
                                        @click="handelView(row, (item.prop === 'customerMobile' ? 'showMobile' : 'showEmail'))"></i>
                                </div>
                            </div>
                            <div class="col-append-box" v-show="item.prop === 'accountNumber'">
                                <div class="col-append-content">
                                    {{ row[item.prop] ? (row.showAccount ? row[item.prop] : '****') : ''}}</div>
                                <div class="col-append-btn">
                                    <i
                                        title="查看完整信息"
                                        v-show="row[item.prop] && !row.showAccount"
                                        class="el-icon-view eye-btn bigData-btn-viewAccount"
                                        @click="handelView(row, 'showAccount')"></i>
                                </div>
                            </div>
                        </template>
                    </edit-table-box>
                </template>
                <template slot="footer">
                    <lots-pagination
                        @size-change="accountList.sizeChange"
                        :current-page.sync="accountList.pageNo"
                        @current-change="accountList.pageChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="accountList.pageTotal" >
                    </lots-pagination>
                </template>
            </list-layout-paper>
            <!-- 表格列配置弹框 -->
            <!-- <column-config
                :pageID="config.name"
                :initColumns="config.columns"
                ref="otpColumnConfig"
                @header-change="config.columns = $event"
            /> -->
        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import editTableBox from '@/components/lots/editTableBox/Index';
import { getCustomerManagerArchivePage, customerManagerArchiveSave, customerManagerArchiveRemove } from '@mdm/api/customer/customerPersonResponsible.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted, onActivated } from '@vue/composition-api';
import { Message, MessageBox } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import reportExport from '@/components/lots/reportExportPlugin';
// import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import _ from 'lodash';

export default {
    name: 'tianfuBankContract',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        // tableBox,
        editTableBox,
        lotsPagination,
        lotsButton,
        reportExport
        // columnConfig
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.customerPersonResponsible);
        const { user } = store.getters;
        // const tenantCode = store.getters.currentTenant.tenantCode;
        class AccountListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        v.edit = -3;
                        v.allowEdit = true;
                        v.disableProps = v.id ? ['respType', 'customerName'] : [];
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const accountList = new AccountListClass({
            Api: {
                listApi: getCustomerManagerArchivePage // 查询接口
            }
        });
        const searchDataFormat = (condition = {}, params = {}) => {
            if (condition.effectTime && condition.effectTime.length) {
                condition.effectStartTime = utils.formatDateTime(condition.effectTime[0]);
                condition.effectEndTime = utils.formatDateTime(condition.effectTime[1]);
                Reflect.deleteProperty(condition, 'effectTime');
            }
            condition.userCode = user.userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return { ...condition, ...params };
        };
        const config = reactive(configStatic);
        const holePageLoading = ref(false);
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const addNew = () => { // 新增合同信息
            // setupContext.refs.editTableBox.addRow();
            accountList.totalData.value.unshift({
                edit: 2,
                allowEdit: true
            });
            const timer = setTimeout(() => { // 解决表格错位
                setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
            });
            timer && clearTimeout(timer);
        };
        const handleChangeRow = ({ prop, val, type, row, index }) => {
            if (prop === 'respDivide') {
                row.disableProps = row.disableProps || (row.id ? ['respType', 'customerName'] : []);
                respDivideAction(row, val);
            }
            if (['effectStartTime', 'effectEndTime'].includes(prop)) {
                if (row.effectStartTime && row.effectEndTime
                    && new Date(row.effectEndTime).getTime() <= new Date(row.effectStartTime).getTime()) {
                    if (prop === 'effectStartTime') {
                        Message.warning('承接开始时间必须早于承接结束时间');
                    } else {
                        Message.warning('承接结束时间必须晚于承接开始时间');
                    }
                    row[prop] = null;
                }
            }
        };
        // eslint-disable-next-line complexity
        const respDivideAction = (row, val) => {
            if (val === '2') { // 按客户
                const arr = row.disableProps;
                row.companyName = null;
                row.companyCode = null;
                row.businessCenterName = null;
                row.businessCenterCode = null;
                row.siteName = null;
                row.siteCode = null;
                arr.every(key => key !== 'companyName') && arr.push('companyName');
                arr.every(key => key !== 'businessCenterName') && arr.push('businessCenterName');
                arr.every(key => key !== 'siteName') && arr.push('siteName');
                row.disableProps = arr;
            } else if (val === '3') { // 按客户+分公司
                row.businessCenterName = null;
                row.businessCenterCode = null;
                row.siteName = null;
                row.siteCode = null;
                const arr = row.disableProps.filter(key => key !== 'companyName');
                arr.every(key => key !== 'businessCenterName') && arr.push('businessCenterName');
                arr.every(key => key !== 'siteName') && arr.push('siteName');
                row.disableProps = arr;
            } else if (val === '4') { // 按客户+经营中心
                row.companyName = null;
                row.companyCode = null;
                row.siteName = null;
                row.siteCode = null;
                const arr = row.disableProps.filter(key => key !== 'businessCenterName');
                arr.every(key => key !== 'companyName') && arr.push('companyName');
                arr.every(key => key !== 'siteName') && arr.push('siteName');
                row.disableProps = arr;
            } else if (val === '5') { // 按客户+服务平台
                row.companyName = null;
                row.companyCode = null;
                row.businessCenterName = null;
                row.businessCenterCode = null;
                const arr = row.disableProps.filter(key => key !== 'siteName');
                arr.every(key => key !== 'companyName') && arr.push('companyName');
                arr.every(key => key !== 'businessCenterName') && arr.push('businessCenterName');
                row.disableProps = arr;
            } else { // 混合或不填
                row.disableProps = row.id ? ['respType', 'customerName'] : [];
            }
        };
        const handleActive = (row, event, index) => {
            if (event === 'edit') {
                respDivideAction(row, row.respDivide);
            }
        };
        const saveLoading = ref(false);
        const handleSave = () => {
            if (!accountList.selectedRows.value.length) {
                Message.warning('请勾选要保存的数据');
                return false;
            } else {
                const checkTableData = _.cloneDeep(accountList.selectedRows.value).map(item => {
                    delete item.backup;
                    delete item.edit;
                    delete item.allowEdit;
                    return item;
                });
                if (!setupContext.refs.editTableBox.tableExamine(checkTableData)) { // 编辑表格数据校验
                    return false;
                };
                let timeCheckPass = true;
                const mastFillStop = checkTableData.some(item => {
                    if (new Date(item.effectEndTime).getTime() <= new Date(item.effectStartTime).getTime()) {
                        timeCheckPass = false;
                    }
                    return (item.respDivide === '3' && !item.companyCode)
                        || (item.respDivide === '4' && !item.businessCenterCode)
                        || (item.respDivide === '5' && !item.siteCode)
                        || new Date(item.effectEndTime).getTime() <= new Date(item.effectStartTime).getTime();
                });
                if (mastFillStop) {
                    if (!timeCheckPass) {
                        Message.warning('承接开始时间不能晚于承接结束时间');
                    } else {
                        Message.warning('责任划分方式为客户+对应维度时，对应维度不能为空');
                    }
                    return false;
                }
                saveLoading.value = true;
                customerManagerArchiveSave(checkTableData).then(res => {
                    if (+res.code === 0) {
                        Message.success('保存成功');
                        accountList.getList();
                    }
                }).finally(() => {
                    saveLoading.value = false;
                });
            }
        };
        const deleteLoading = ref(false);
        const handleDelete = () => {
            if (!accountList.selectedRows.value.length) {
                Message.warning('请勾选要删除的数据');
                return false;
            } else if (accountList.selectedRows.value.every(item => !item.id)) {
                accountList.selectedRows.value.forEach(item => {
                    // eslint-disable-next-line eqeqeq
                    const theIndex = accountList.totalData.value.findIndex(tableItem => tableItem == item);
                    theIndex !== -1 && accountList.totalData.value.splice(theIndex, 1);
                });
                return false;
            } else {
                MessageBox.confirm('是否确认要删除?', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const ids = accountList.selectedRows.value.filter(item => !!item.id).map(sub => sub.id);
                    deleteLoading.value = true;
                    customerManagerArchiveRemove({ ids }).then(res => {
                        if (+res.code === 0) {
                            Message.success('删除成功');
                            accountList.getList();
                        }
                    }).finally(() => {
                        deleteLoading.value = false;
                    });
                });
            }
        };
        const handleExport = async(submit, type) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = accountList.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const params = {
                ...searchDataFormat(newData),
                deleteFlag: 0
            };
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            const keys = ['customerCodes', 'companyCodes', 'businessCenterCodes', 'siteCodes', 'ownerCodes'];
            keys.forEach(key => {
                if (params[key]) {
                    params[key] = params[key].split(',');
                }
            });
            submit(params);
        };
        const rendertable = ref(true);
        const handelView = (row, prop) => {
            row[prop] = true;
            rendertable.value = !rendertable.value;
        };
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 解决表格错位
        });
        onMounted(() => {
            const queryParams = {
                // effectTime: utils.defaultDate('365')
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
        });
        return {
            accountList,
            config,
            otpColumnConfig,
            showColumnConfigDialog,
            addNew,
            holePageLoading,
            utils,
            authorityCode,
            handleChangeRow,
            saveLoading,
            handleSave,
            deleteLoading,
            handleDelete,
            handleExport,
            handelView,
            rendertable,
            handleActive
        };
    }
};
</script>
<style lang="less">
.customer-person {
    width: 100%;
    .customer-person-paper {
        height: 100%;
    }
    .header-button {
        margin-left: 10px;
    }
    .list-layout-wrapper {
        height: 100%;
    }

    .el-tabs {
        width: 100%;
    }
    .list-layout .list-header .el-input {
        margin-left: 0;
    }
    .flex-layout .list-main {
        overflow: auto;
    }

    .flex-layout .table {
        overflow-y: auto;
    }

    .layout-content.customer-person-paper {
        padding: 0px 0px 0px;
    }
    .col-append-box {
        display: flex;
        align-items: center;
        .col-append-btn {
            padding-bottom: 3px;
            padding-left: 3px;
            .eye-btn {
                display: inline-block;
                width: 10px;
                height: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>

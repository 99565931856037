import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 客户负责人归档列表查询
export const getCustomerManagerArchivePage = (data) => {
    return request({
        url: apiCrmHost + '/customer/manager/archive/page',
        method: 'post',
        data
    });
};
// 客户负责人归档信息保存
export const customerManagerArchiveSave = (data) => {
    return request({
        url: apiCrmHost + '/customer/manager/archive/save',
        method: 'post',
        data
    });
};
// 客户负责人归档信息删除
export const customerManagerArchiveRemove = (data) => {
    return request({
        url: apiCrmHost + '/customer/manager/archive/remove',
        method: 'DELETE',
        data
    });
};
